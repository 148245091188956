
import { defineComponent, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import BANCard from "@/shared/components/cards/BANCard.vue";
import TransactionCard from "@/shared/components/cards/TransactionCard.vue";
import { useGetTransactionList } from "@/shared/composables/api/useGetTransactionList";
import { useQuery } from "@vue/apollo-composable";
import { transactionStatisticsGql } from "@/api/transactions/transactionStats";
import {
  TransactionStatistics,
  TransactionStatistics_transactionStatistics_TransactionStatistics,
} from "@/api/transactions/__generated__/TransactionStatistics";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import GlobalSearchInput from "@/shared/components/forms/GlobalSearchInput.vue";
import GhostListHeader from "@/shared/components/layouts/GhostListHeader.vue";
import PageHeaderTitle from "@/shared/components/typography/PageHeaderTitle.vue";
import routeNames from "@/web/router/routeNames";
import List from "@/shared/components/lists/List.vue";
import { convertAmountToAda } from "@/shared/utils/numbers";
import { blocksGql } from "@/api/blocks/blockList";
import {
  Blocks,
  Blocks_blocks_BlockResults,
  BlocksVariables,
} from "@/api/blocks/__generated__/Blocks";

export default defineComponent({
  setup() {
    const t = useI18n().t;

    const gotoTransactionDetails = (hash) => ({
      name: routeNames.transactionDetails,
      params: { hash },
    });

    const transactionsStatistics = useQuery<TransactionStatistics>(
      transactionStatisticsGql,
      {}
    );
    const parsedGqlTransStatistics = computed(() =>
      parseGqlResponse<TransactionStatistics_transactionStatistics_TransactionStatistics>(
        "TransactionStatistics",
        transactionsStatistics?.result?.value
      )
    );

    const latestBlock = useQuery<Blocks, BlocksVariables>(
      blocksGql,
      () => ({
        filters: {
          pagination: {
            timestamp: new Date().toISOString(),
            pageSize: 1,
          },
        },
      }),
      () => ({
        pollInterval: 5000,
      })
    );

    const parsedGqlLatestBlock = computed(() =>
      parseGqlResponse<Blocks_blocks_BlockResults>(
        "BlockResults",
        latestBlock?.result?.value
      )
    );

    const latestBlockId = computed(() => {
      if (
        parsedGqlLatestBlock?.value?.data?.results != null &&
        parsedGqlLatestBlock?.value?.data?.results?.length > 0
      ) {
        return parsedGqlLatestBlock?.value?.data?.results[0]?.id;
      }

      return null;
    });

    const transactionStats = computed(() => {
      return [
        {
          id: 1,
          cardTitle: t("total quantity"),
          cardAmount: parsedGqlTransStatistics?.value?.data?.transactionsCount,
          unitType: "blocks",
        },
        {
          id: 2,
          cardTitle: t("total amount"),
          cardAmount: convertAmountToAda(
            parsedGqlTransStatistics?.value?.data?.amount
          ),
        },
        {
          id: 3,
          cardTitle: t("total fee"),
          cardAmount: convertAmountToAda(
            parsedGqlTransStatistics?.value?.data?.feeAmount
          ),
        },
      ];
    });

    const {
      mappedDataForDisplay: transactionListForDisplay,
      showMore,
      showMoreIsLoading,
      isLoading: isListLoading,
    } = useGetTransactionList();

    return {
      t,
      transactionStats,
      transactionsStatisticsQuery: transactionsStatistics,
      transactionListForDisplay,
      searchRef: ref(""),
      gotoTransactionDetails,
      showMoreIsLoading,
      showMore,
      isListLoading,
      latestBlockId,
    };
  },
  components: {
    BANCard,
    TransactionCard,
    List,
    GlobalSearchInput,
    GhostListHeader,
    PageHeaderTitle,
  },
});

import gql from "graphql-tag";

export const transactionStatisticsGql = gql`
  query TransactionStatistics {
    transactionStatistics {
      ... on TransactionStatistics {
        transactionsCount
        amount
        feeAmount
      }
      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;


import { computed, defineComponent, PropType, toRef } from "vue";
import { useI18n } from "vue-i18n";
import BlockCardLayoutBoxedItem from "@/shared/components/cards/BlockCardLayoutBoxedItem.vue";
import BlockCardLayout from "@/shared/components/cards/BlockCardLayout.vue";
import Tooltip from "@/shared/components/cards/Tooltip.vue";
import { TransactionListDisplayItem } from "@/shared/composables/api/useGetTransactionList";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";

export default defineComponent({
  props: {
    transactionCardDetails: {
      type: Object as PropType<TransactionListDisplayItem>,
      required: true,
    },
    latestBlockId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const t = useI18n().t;
    const latestBlockId = toRef(props, "latestBlockId");
    const transactionCardDetails = toRef(props, "transactionCardDetails");

    const differenceFromLatestBlock = computed(() => {
      return (
        parseFloat(latestBlockId.value) -
        parseFloat(transactionCardDetails?.value?.blockNumber ?? "0")
      );
    });

    return {
      differenceFromLatestBlock,
      t,
    };
  },
  components: {
    FullWrapContainer,
    BlockCardLayoutBoxedItem,
    BlockCardLayout,
    Tooltip,
  },
});
